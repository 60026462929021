<template>
  <div class="page-table yetkiTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">
      Yetki Tanımla
    </div>

    <el-row :gutter="10" v-loading="loading || yetkiListLoading"
            :element-loading-text='loading ? $t("src.views.apps.yetki.tanimlama.addLoading") : $t("src.views.apps.yetki.yetkiListLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="yetkiForm" :rules="rulesYetki" ref="yetkiForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10">
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Personel" prop="yoneticiID">:
                  <el-select style="width: 100%" size="small" v-model="yetkiForm.yoneticiID"
                             placeholder="Personel Seçiniz" @change="yoneticiSecimi" filterable
                             :filter-methos="filterMethod">
                    <el-option
                        v-for="item in yoneticiler"
                        :key="item.yoneticiID"
                        :label="item.adSoyad"
                        :value="item.yoneticiID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse" v-if="isDesktop ">
            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.yetki.tanimlama.title")' name="6">
              <div style="padding: 0px 10px 0px 10px" class="sozlesmeSelect">
                <div v-for="(modul, modulIndex) in gruplanmisYetkiler" :key="modulIndex">
                  <el-row :gutter="10" v-loading="yetkiListLoading"
                          :element-loading-text='$t("src.views.apps.yetki.yetkiListLoading")'
                          element-loading-spinner="el-icon-loading"
                          element-loading-background="rgba(255, 255, 255, 1)">
                    <el-row style="text-align: center; background-color:#c7e3f6; color:#00385a;">
                      <el-col :lg="5" :md="5" :sm="5" :xs="5" style="text-align: center;">
                        &nbsp;
                      </el-col>
                      <el-col :lg="14" :md="14" :sm="14" :xs="14" style="text-align: center;">
                        <b> {{ modul.modul }} </b>
                      </el-col>
                      <el-col :lg="5" :md="5" :sm="5" :xs="5">
                        <el-switch v-model="modul.switchState" active-color="#13ce66" inactive-color="#ff4949"
                                   active-value="1" inactive-value="0"
                                   @change="toggleModulYetkileri(modul)"
                                   style="text-align: right !important;"></el-switch>
                      </el-col>
                    </el-row>

                    <el-row v-show="modul.switchState" v-for="(item, itemIndex) in modul.yetkiler" :key="itemIndex"
                            style="text-align: center;">
                      <el-col :lg="5" :md="5" :sm="5" :xs="5" style="text-align: center;">
                        {{ item.yetkiKodu }}
                      </el-col>
                      <el-col :lg="14" :md="14" :sm="14" :xs="14" style="text-align: center;">
                        {{ item.aciklama }}
                      </el-col>
                      <el-col :lg="5" :md="5" :sm="5" :xs="5">
                        <el-switch v-model="item.secili" active-color="#13ce66" inactive-color="#ff4949"
                                   active-value="1" inactive-value="0"
                                   @change="atama(item.yetkiKodu, item.secili)"
                                   style="text-align: right !important;"></el-switch>
                      </el-col>
                    </el-row>

                  </el-row>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>

          <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse" v-if="!isDesktop">
            <div style="padding: 0px 10px 0px 10px" class="sozlesmeSelect">
              <el-table :data="gruplanmisYetkiler" border>
                <el-table-column prop="modul" label="Modül" width="150">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line; text-align: center;">
                      {{ scope.row.modul }}
                      <el-switch v-model="scope.row.switchState" active-color="#13ce66" inactive-color="#ff4949"
                                 active-value="1" inactive-value="0"
                                 @change="toggleModulYetkileri(scope.row)"
                                 style="display: inline-block; margin-left: 10px;"></el-switch>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Yetkiler">
                  <template slot-scope="scope">
                    <el-table :data="scope.row.yetkiler" border>
                      <el-table-column prop="yetkiKodu" label="Yetki Kodu" width="200"></el-table-column>
                      <el-table-column prop="aciklama" label="Açıklama" width="450"></el-table-column>
                      <el-table-column prop="secili" label="Switch" width="70">
                        <template slot-scope="item">
                          <el-switch v-model="item.row.secili" active-color="#13ce66" inactive-color="#ff4949"
                                     active-value="1" inactive-value="0"
                                     @change="atama(item.row.yetkiKodu, item.row.secili)"></el-switch>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-collapse>


        </el-col>

        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">

            <el-collapse-item class="card-shadow--medium pt-10" name="8" :title='$t("src.views.apps.genel.durum")'>
              <el-row>
                <el-button size="mini"
                           v-on:click="yetkiAtama('yetkiForm', yetkiForm.yoneticiID, yetkiForm.atanacakYetkiler)"
                           class="onayBtn" type="primary">
                  <label>Kaydet</label>
                </el-button>
                &nbsp;
                <el-popover placement="bottom" width="320" v-model="visibleSil">
                  <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="primary" @click="resetForm('yetkiForm')">
                      {{ $t('src.views.apps.genel.yes') }}
                    </el-button>
                    <el-button size="mini" @click="visibleSil = false;">
                      {{ $t('src.views.apps.genel.no') }}
                    </el-button>
                  </div>
                  <el-button size="mini" slot="reference">
                    {{ $t('src.views.apps.genel.temizle') }}
                  </el-button>
                </el-popover>
              </el-row>
            </el-collapse-item>

          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
import musteriService from '../../../WSProvider/MusteriService'
import notification from '../../../notification'
import yoneticiService from '../../../WSProvider/YoneticiService';

var moment = require('moment');
let time = moment().format('YYYY-MM-DD');

export default {
  name: "YetkiTanimla",
  components: {},

  created() {
    this.checkDevice();
  },


  mounted() {
    this.gruplanmisYetkiler.forEach(modul => {
      modul.switchState = "0";
      modul.yetkiler.forEach(yetki => {
        yetki.secili = "0";
      });
    });

    window.addEventListener('resize', this.checkDevice);
    //this.getYoneticiListesi();
    this.getYetkiListesi();
    this.getYoneticilerinYetkileri();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },

  data() {
    return {
      secim: false,
      isDesktop: false,
      loading: false,
      yetkiListLoading: false,
      visibleSil: false,
      disabled: false,

      secilenYonetici: "",
      musteriler: [],
      yoneticiler: [],
      yetkiler: [],
      gruplanmisYetkiler: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'tr',
      },
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],

      yetkiForm: {
        yoneticiID: '',
        atanacakYetkiler: []
      },
      selectIcon: '1',
      page: 0,
      postPage: 0,
      sizes: 10,

      rulesYetki: {
        yoneticiAdi: [{
          required: true,
          message: "Lütfen Yönetici Seçiniz",
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {

    yoneticiSecimi(event) {
      this.secim = true;
      var item = this.yoneticiler.find((el) => el.yoneticiID == event);
      //console.log(item);

      this.gruplanmisYetkiler.forEach(modul => {
        //console.log(modul)
        modul.yetkiler.forEach(yetki2 => {
          yetki2.secili = item.yetkiler.some(yetki1 => yetki1.yetkiKodu === yetki2.yetkiKodu) ? "1" : "0";
        });

        if (modul.yetkiler.every(yetkiItem => yetkiItem.secili === "1")) {
          modul.switchState = "1";
        } else {
          modul.switchState = "0";
        }
      });


      this.yetkiForm.atanacakYetkiler = item.yetkiler.map(yetki => yetki.yetkiKodu);

      //console.log(this.yetkiForm.atanacakYetkiler)
    },

    toggleModulYetkileri(modul) {
      //console.log(modul)
      const secili = modul.switchState
      /*modul.yetkiler.forEach(item => {
          item.secili = secili;
      });    */

      if (secili == 0) {
        modul.yetkiler.forEach(item => {
          item.secili = "0"
          //console.log(this.yetkiForm.atanacakYetkiler)
          const index = this.yetkiForm.atanacakYetkiler.findIndex(yetkiKodu => yetkiKodu === item.yetkiKodu);
          if (index !== -1) {
            this.yetkiForm.atanacakYetkiler.splice(index, 1);
          }
        });
      } else {
        modul.yetkiler.forEach(item => {
          item.secili = "1"
          if (!this.yetkiForm.atanacakYetkiler.includes(item.yetkiKodu)) {
            this.yetkiForm.atanacakYetkiler.push(item.yetkiKodu);
          }
        });
      }
    },


    atama(yetkiKodu, secili) {
      //console.log("atama")
      //console.log(yetkiKodu)
      const index = this.yetkiForm.atanacakYetkiler.indexOf(yetkiKodu);
      const currentModul = this.gruplanmisYetkiler.find(modul => modul.yetkiler.some(yetki => yetki.yetkiKodu === yetkiKodu));
      //console.log("---")
      //console.log(currentModul)
      /*
      currentModul.yetkiler.forEach(yetki => {
          if (
              (yetki.yetkiKodu == "YK-yonetimU100" && yetki.secili == 1) ||
              (yetki.yetkiKodu == "YK-yonetimU200" && yetki.secili == 1) ||
              (yetki.yetkiKodu == "YK-yonetimU300" && yetki.secili == 1)
          ) {
              if (yetki.yetkiKodu == "YK-yonetimR100") {
                  yetki.secili = 1;
              }
          }
      })*/

      let yonetimR100 = currentModul.yetkiler.find(yetki => yetki.yetkiKodu === "YK-yonetimR100");
      let musteriR100 = currentModul.yetkiler.find(yetki => yetki.yetkiKodu === "YK-musteriR100");
      let projeR200 = currentModul.yetkiler.find(yetki => yetki.yetkiKodu === "YK-projeR200");
      let sozlesmeR200 = currentModul.yetkiler.find(yetki => yetki.yetkiKodu === "YK-sozlesmeR200");
      let resmiTatilR200 = currentModul.yetkiler.find(yetki => yetki.yetkiKodu === "YK-resmiTatilR200");
      let etutR200 = currentModul.yetkiler.find(yetki => yetki.yetkiKodu === "YK-etutR200");


      let yetkilerYonetim = currentModul.yetkiler.filter(yetki =>
          yetki.yetkiKodu === "YK-yonetimU100" || yetki.yetkiKodu === "YK-yonetimU200" || yetki.yetkiKodu === "YK-yonetimU300"
      );
      let listeYonetim = yetkilerYonetim.some(yetki => yetki.secili == 1);
      if (listeYonetim) {
        yonetimR100.secili = "1"
      }

      let yetkilerMusteri = currentModul.yetkiler.filter(yetki =>
          yetki.yetkiKodu === "YK-musteriU100" || yetki.yetkiKodu === "YK-musteriR200"
      );
      let listeMusteri = yetkilerMusteri.some(yetki => yetki.secili == 1);
      if (listeMusteri) {
        musteriR100.secili = "1"
      }

      let yetkilerProje = currentModul.yetkiler.filter(yetki =>
          yetki.yetkiKodu === "YK-projeR100" || yetki.yetkiKodu === "YK-projeU300"
      );
      let listeProje = yetkilerProje.some(yetki => yetki.secili == 1);
      if (listeProje) {
        projeR200.secili = "1"
      }

      let yetkilerSozlesme = currentModul.yetkiler.filter(yetki =>
          yetki.yetkiKodu === "YK-sozlesmeR100" || yetki.yetkiKodu === "YK-sozlesmeU100" || yetki.yetkiKodu === "YK-sozlesmeD100" || yetki.yetkiKodu === "YK-sozlesmeD300"
      );
      let listeSozlesme = yetkilerSozlesme.some(yetki => yetki.secili == 1);
      if (listeSozlesme) {
        sozlesmeR200.secili = "1"
      }

      let yetkilerTatil = currentModul.yetkiler.filter(yetki =>
          yetki.yetkiKodu === "YK-resmiTatilU100" || yetki.yetkiKodu === "YK-resmiTatilR100"
      );
      let listeTatil = yetkilerTatil.some(yetki => yetki.secili == 1);
      if (listeTatil) {
        resmiTatilR200.secili = "1"
      }

      let yetkilerEtut = currentModul.yetkiler.filter(yetki =>
          yetki.yetkiKodu === "YK-etutR100" || yetki.yetkiKodu === "YK-etutU100"
      );
      let listeEtut = yetkilerEtut.some(yetki => yetki.secili == 1);
      if (listeEtut) {
        etutR200.secili = "1"
      }

      //console.log(currentModul.yetkiler)
      if (secili == 1) {
        if (currentModul.yetkiler.every(yetkiItem => yetkiItem.secili == "1")) {
          currentModul.switchState = "1"
        }

        if (index === -1) {
          this.yetkiForm.atanacakYetkiler.push(yetkiKodu);
        }
      } else {
        if (currentModul) {
          currentModul.switchState = "0"
        }
        if (index !== -1) {
          this.yetkiForm.atanacakYetkiler.splice(index, 1);
        }
      }
    },

    yetkiAtama(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Yetki atamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            this.loading = true;
            if (this.yetkiForm.yoneticiID == "") {
              notification.Status("warning", this, "Lütfen Yönetici Seçiniz")
              this.loading = false;
              return;
            }

            /*
                                        if(
                                            (this.yetkiForm.atanacakYetkiler.includes('YK-yonetimU100')  ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-yonetimU200')  ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-yonetimU300')) && !this.yetkiForm.atanacakYetkiler.includes('YK-yonetimR100')){
                                                notification.Status("warning", this, "Bu işlem için yönetici listeleme yetkisi de vermelisiniz.")
                                                this.loading = false;
                                                return;
                                        }

                                        if(
                                            (this.yetkiForm.atanacakYetkiler.includes('YK-musteriU100') ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-musteriR200')) && !this.yetkiForm.atanacakYetkiler.includes('YK-musteriR100')){
                                            notification.Status("warning", this, "Bu işlem için müşteri listeleme yetkisi de vermelisiniz.")
                                            this.loading = false;
                                            return;
                                        }

                                        if(
                                            (this.yetkiForm.atanacakYetkiler.includes('YK-projeR100') ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-projeU300')) && !this.yetkiForm.atanacakYetkiler.includes('YK-projeR200')){
                                            notification.Status("warning", this, "Bu işlem için proje listeleme yetkisi de vermelisiniz.")
                                            this.loading = false;
                                            return;
                                        }

                                        if(
                                            (this.yetkiForm.atanacakYetkiler.includes('YK-sozlesmeR100') ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-sozlesmeU100') ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-sozlesmeD100') ||
                                            this.yetkiForm.atanacakYetkiler.includes('YK-sozlesmeR300')) && !this.yetkiForm.atanacakYetkiler.includes('YK-sozlesmeR200')){
                                            notification.Status("warning", this, "Bu işlem için sözleşme listeleme yetkisi de vermelisiniz.")
                                            this.loading = false;
                                            return;
                                        }*/

            yoneticiService.yetkiAtama(this.yetkiForm.yoneticiID, this.yetkiForm.atanacakYetkiler).then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token)
              }
              this.loading = false;
              notification.Status("success", this, "Yetki Ataması Başarı ile Tamamlandı")
              this.getYoneticilerinYetkileri();
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg)
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            })
          }).catch((e) => {
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },


    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },

    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.yetkiListLoading = false;
      this.stateLoading = false;

      //this.getYoneticiListesi();
      this.getYetkiListesi();
      this.getYoneticilerinYetkileri();
    },


    getYoneticiListesi() {
      try {
        this.yetkiListLoading = true
        yoneticiService.yoneticiListe(this.selectIcon, this.postPage, this.sizes, 2).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.yoneticiler = response.data
          }
          this.yetkiListLoading = false
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this)
            } else if (error.status == 404) {
              this.yoneticiler = []
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {

          }
          this.yetkiListLoading = false
        })
      } catch (e) {
        this.yetkiListLoading = false
      }
    },

    getYetkiListesi() {
      try {
        this.yetkiListLoading = true
        yoneticiService.yetkiListesi().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.yetkiler = response.data
            const gruplanmisYetkiler = [];
            this.yetkiler.forEach((yetki) => {
              //console.log(gruplanmisYetkiler)
              const modulIndex = gruplanmisYetkiler.findIndex((modul) => modul.modul === yetki.modul);
              if (modulIndex !== -1) {
                gruplanmisYetkiler[modulIndex].yetkiler.push(yetki);
              } else {
                gruplanmisYetkiler.push({modul: yetki.modul, yetkiler: [yetki]});
              }
            });
            this.gruplanmisYetkiler = gruplanmisYetkiler
          }
          this.loading = false
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this)
            } else if (error.status == 404) {
              this.yetkiler = []
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {

          }
          this.loading = false
        })
      } catch (e) {
        this.loading = false
      }
    },

    getYoneticilerinYetkileri() {
      try {
        this.yetkiListLoading = true
        yoneticiService.yoneticilerinYetkileri().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.yoneticiler = response.data

          }
          this.yetkiListLoading = false
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this)
            } else if (error.status == 404) {
              this.yoneticiler = []
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {

          }
          this.yetkiListLoading = false
        })
      } catch (e) {
        this.yetkiListLoading = false
      }
    },

    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      // this.getYoneticiListesi();
      this.getYetkiListesi();
      this.getYoneticilerinYetkileri();
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      // this.getYoneticiListesi();
      this.getYetkiListesi();
      this.getYoneticilerinYetkileri();
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      // this.getYoneticiListesi();
      this.getYetkiListesi();
      this.getYoneticilerinYetkileri();
    },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.yetkiListLoading = false;
      this.stateLoading = false;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
      this.resetSwitches();

    },

    resetSwitches() {
      this.gruplanmisYetkiler.forEach(modul => {
        modul.switchState = "0";
        modul.yetkiler.forEach(yetki => {
          yetki.secili = "0";
        });
      });
    },

  }
}

</script>


<style lang="scss">
.sozlesmeSelect {
  .el-row {
    padding: 10px 10px 10px 8px;
  }

  .el-row:nth-child(2n) {
    background: #f3f7fa !important
  }
}
</style>